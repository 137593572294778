// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.closedContainer {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
}

.homeSection {
  text-align: left;
  margin-left: 100px;
  margin-right: 100px;

  @media only screen and (max-width: 600px) {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.homeSectionHeader {
  font-size: 34px;
  font-weight: bold;

  @media only screen and (max-width: 600px) {
    font-size: 30px;
  }
}

.homeSectionText {
  line-height: 150%;
  font-size: 27px;

  @media only screen and (max-width: 600px) {
    font-size: 22px;
  }
}

.homeWelcomeTransitionSection {
  height: 480px;

  @media only screen and (max-width: 600px) {
    height: 240px;
  }
}

.homeTransitionSection {
  height: auto;
}

.homeTechnologyIconGrid {
  text-align: center;
  line-height: 150%;
  font-size: 27px;
}

.homeTechnologyIconGridText {
  font-size: 27px;

  @media (max-width: 660px) {
    font-size: 20px;
  }
}

.sitesContainer {
  padding-top: 3px;
}
`, "",{"version":3,"sources":["webpack://./src/app/home/index.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,mBAAmB;;EAEnB;IACE,iBAAiB;IACjB,kBAAkB;EACpB;AACF;;AAEA;EACE,eAAe;EACf,iBAAiB;;EAEjB;IACE,eAAe;EACjB;AACF;;AAEA;EACE,iBAAiB;EACjB,eAAe;;EAEf;IACE,eAAe;EACjB;AACF;;AAEA;EACE,aAAa;;EAEb;IACE,aAAa;EACf;AACF;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,eAAe;;EAEf;IACE,eAAe;EACjB;AACF;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".closedContainer {\n  padding-left: 10px;\n  padding-right: 10px;\n  padding-top: 10px;\n}\n\n.homeSection {\n  text-align: left;\n  margin-left: 100px;\n  margin-right: 100px;\n\n  @media only screen and (max-width: 600px) {\n    margin-left: 10px;\n    margin-right: 10px;\n  }\n}\n\n.homeSectionHeader {\n  font-size: 34px;\n  font-weight: bold;\n\n  @media only screen and (max-width: 600px) {\n    font-size: 30px;\n  }\n}\n\n.homeSectionText {\n  line-height: 150%;\n  font-size: 27px;\n\n  @media only screen and (max-width: 600px) {\n    font-size: 22px;\n  }\n}\n\n.homeWelcomeTransitionSection {\n  height: 480px;\n\n  @media only screen and (max-width: 600px) {\n    height: 240px;\n  }\n}\n\n.homeTransitionSection {\n  height: auto;\n}\n\n.homeTechnologyIconGrid {\n  text-align: center;\n  line-height: 150%;\n  font-size: 27px;\n}\n\n.homeTechnologyIconGridText {\n  font-size: 27px;\n\n  @media (max-width: 660px) {\n    font-size: 20px;\n  }\n}\n\n.sitesContainer {\n  padding-top: 3px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
